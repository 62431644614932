<template>
	<section class="content">
		<div class="container-fluid">
			<div class="card" v-if="isKurir">
				<div class="card-body">
					<div class="row">
						<div class="col-sm-4">
							<div class="text-secondary">
								<h2 class="float-left mr-2">
									
								</h2>
								<div class="mb-3">
									<h4 class="m-0">
										Halo
										<strong>{{ username }} !</strong>
									</h4>
								</div>
							</div>
						</div>
						<div class="col-sm-8 row">
							<div class="col-sm-4">
				                <div class="info-box vertical-center box-border row rasio" style="min-height: 50px; margin-right: 8px;">
				                  <div class="col-12 col-sm-8" style="padding: 0px;display: flex;">
				                    <i class="fas fa-box fa-2x" style="color: cornflowerblue;"></i>
				                    <h6 class="info-text" style="align-self: flex-end; margin-left: 4px;">Total Kiriman</h6>
				                  </div>
				                  <div class="col-12 col-sm-4" style="padding: 0px;text-align: right;align-self: center;">
				                    <span style="font-size: initial; font-weight: 600; text-align: right;">{{ dataStatus.total_kiriman }}</span>
				                  </div>
				                </div>
				              </div>
							<div class="col-sm-4">
				                <div class="info-box vertical-center box-border row rasio" style="min-height: 50px; margin-right: 8px;">
				                  <div class="col-12 col-sm-12 col-md-12 col-lg-8" style="padding: 0px;display: flex;">
				                    <i class="far fa-check-circle fa-2x" style="color: lawngreen;"></i>
				                    <h6 class="info-text" style="align-self: flex-end;margin-left: 4px;">Rasio Sukses</h6>
				                  </div>
				                  <div class="col-12 col-sm-12 col-md-12 col-lg-4" style="padding: 0px;text-align: right; align-self: center;">
				                    <span style="font-size: initial; font-weight: 600; text-align: right;align-self: flex-end;">{{ dataStatus.rasio_sukses }}%</span>
				                  </div>
				                </div>
				              </div>
				              <div class="col-sm-4">
				                <div class="info-box vertical-center box-border row rasio" style="min-height: 50px; margin-right: 8px;">
				                  <div class="col-12 col-sm-8" style="padding: 0px;display: flex;">
				                    <i class="fas fa-undo-alt fa-2x" style="color: red;"></i>
				                    <h6 class="info-text" style="align-self: flex-end;margin-left: 4px;">Rasio Retur</h6>
				                  </div>
				                  <div class="col-12 col-sm-4" style="padding: 0px;text-align: right;align-self: center;">
				                    <span style="font-size: initial; font-weight: 600; text-align: right;">{{ dataStatus.rasio_retur }}%</span>
				                  </div>
				                </div>
				              </div>
						</div>
					</div>
				</div>
			</div>
			<div class="row justify-content-end">
				<div class="col-lg-12 d-flex justify-content-end">
				<div class="col-lg-9">
				</div>
        		</div>
				<div class="col-lg-3 col-md-3 col-sm-5 col-xs-12">
					<div class="form-group form-horizontal">
						<v-select :options="provinces" :reduce="(opt) => opt.code" v-model="province"
							@input="selectOrigin">
							</v-select>
					</div>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-5 col-xs-12">

					<div class="form-group form-horizontal">
						<v-select :options="cities" :reduce="(opt) => opt.code" v-model="city"
							@input="selectCity">
							</v-select>
					</div>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-5 col-xs-12">
					<div class="input-group mb-3">
						<input type="text" ref="daterange" class="form-control" style="border-right: 0" />
						<div class="input-group-append">
							<div class="input-group-text" style="background-color: #fff">
								<span v-if="loading" class="spinner-border spinner-border-sm" role="status"
									aria-hidden="true"></span>
								<span v-if="isReady">
									<span class="fa fa-calendar"></span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row mt-3">
                <!--PERSIAPAN-->
		        <div class="info-box row" style="width: 100%;border-radius: 16px;padding: 0px;margin: .5rem;">
		          <div class="col-12 col-sm-2 cat">
		            <div class="info-box-content text-light vertical-center" style="padding: 5px;">
		              <h5 class="info-box-number"><i class="fas fa-box"></i></h5>
		              <h5 class="info-box-text">Persiapan</h5>
		            </div>
		          </div>
		          <div class="col-12 col-sm-10 row margin-0">
		            <div class="col-12 col-sm-6 col-md-3">
					  <span @click="dashortcut('draft')" style="cursor: pointer;">
		                <div class="info-box mb-3 vertical-center box-border row">
		                  <div class="row" style="padding-left: 10px;">
		                    <i class="hxf hxf-draft" style="font-size: 24px;"></i><h5 class="info-text">Draft</h5>
		                  </div>
		                  <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
		                    <div class="info-box-content text-dark" style="padding: 0px;" v-bind:class="{ 'skeleton-item': loading }">
		                      <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.draft }}</h5>
		                    </div>
		                  </div>
		                </div>
					  </span>
		              <!-- /.info-box -->
		            </div>
		            <div class="col-12 col-sm-6 col-md-3">
		              <span @click="dashortcut('rpick')" style="cursor: pointer;">
		                <div class="info-box mb-3 vertical-center box-border row">
		                  <div class="row" style="padding-left: 10px;">
		                    <i class="hxf hxf-request-pickup" style="font-size: 24px;"></i><h5 class="info-text">Request Pickup</h5>
		                  </div>
		                  <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
		                    <div class="info-box-content text-dark" style="padding: 0px;" v-bind:class="{ 'skeleton-item': loading }">
		                      <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.reqPickup }}</h5>
		                    </div>
		                  </div>
		                </div>
		              </span>
		              <!-- /.info-box -->
		            </div>
		            
		            <div class="col-12 col-sm-6 col-md-3">
		              <span @click="dashortcut('canc')" style="cursor: pointer;">
		                <div class="info-box mb-3 vertical-center box-border row">
		                  <div class="row" style="padding-left: 10px;">
		                    <i class="fa fa-ban text-primary" style="font-size: 24px;"></i><h5 class="info-text">Cancel Pesanan</h5>
		                  </div>
		                  <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
		                    <div class="info-box-content text-dark" style="padding: 0px;" v-bind:class="{ 'skeleton-item': loading }">
		                      <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.cancel }}</h5>
		                    </div>
		                  </div>
		                </div>
		              </span>
		              <!-- /.info-box -->
		            </div>
		          </div>
		        </div>
                <!--PENGIRIMAN-->
		        <div class="info-box row" style="width: 100%;border-radius: 16px;padding: 0px;margin: .5rem;">
		          <div class="col-12 col-sm-2 cat">
		            <div class="info-box-content text-light vertical-center" style="padding: 5px;">
		              <h5 class="info-box-number"><i class="fas fa-car"></i></h5>
		              <h5 class="info-box-text">Pengiriman</h5>
		            </div>
		          </div>
		          <div class="col-12 col-sm-10 row margin-0">
		            <div class="col-12 col-sm-6 col-md-3">
		              <span @click="dashortcut('delgo')" style="cursor: pointer;">
		                <div class="info-box mb-3 vertical-center box-border row">
		                  <div class="row" style="padding-left: 10px;">
		                    <i class="hxf hxf-delivery" style="font-size: 24px;"></i><h5 class="info-text">Pengiriman</h5>
		                  </div>
		                  <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
		                    <div class="info-box-content text-dark" style="padding: 0px;" v-bind:class="{ 'skeleton-item': loading }">
		                      <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.delivery }}</h5>
		                    </div>
		                  </div>
		                </div>
		              </span>
		              <!-- /.info-box -->
		            </div>
		            <div class="col-12 col-sm-6 col-md-3">
		              <span @click="dashortcut('deler')" style="cursor: pointer;">
		                <div class="info-box mb-3 vertical-center box-border row">
		                  <div class="row" style="padding-left: 10px;">
		                    <i class="fa fa-exclamation-triangle text-primary" style="font-size: 24px;"></i><h5 class="info-text">Problem</h5>
		                  </div>
		                  <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
		                    <div class="info-box-content text-dark" style="padding: 0px;" v-bind:class="{ 'skeleton-item': loading }">
		                      <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.problem }}</h5>
		                    </div>
		                  </div>
		                </div>
		              </span>
		              <!-- /.info-box -->
		            </div>
		            
		            <div class="col-12 col-sm-6 col-md-3">
		              <span @click="dashortcut('delok')" style="cursor: pointer;">
		                <div class="info-box mb-3 vertical-center box-border row">
		                  <div class="row" style="padding-left: 10px;">
		                    <i class="hxf hxf-delivered" style="font-size: 24px;"></i><h5 class="info-text">Telah Diterima</h5>
		                  </div>
		                  <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
		                    <div class="info-box-content text-dark" style="padding: 0px;" v-bind:class="{ 'skeleton-item': loading }">
		                      <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.delivered }}</h5>
		                    </div>
		                  </div>
		                </div>
		              </span>
		              <!-- /.info-box -->
		            </div>
		            <div class="col-12 col-sm-6 col-md-3">
		              <span @click="dashortcut('retgo')" style="cursor: pointer;">
		                <div class="info-box mb-3 vertical-center box-border row">
		                  <div class="row" style="padding-left: 10px;">
		                    <i class="hxf hxf-returned" style="font-size: 24px;"></i><h5 class="info-text">Proses Retur</h5>
		                  </div>
		                  <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
		                    <div class="info-box-content text-dark" style="padding: 0px;" v-bind:class="{ 'skeleton-item': loading }">
		                      <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.returngo }}</h5>
		                    </div>
		                  </div>
		                </div>
		              </span>
		              <!-- /.info-box -->
		            </div>
		          </div>
		        </div>
                <!--RETUR-->
		        <div class="info-box row" style="width: 100%;border-radius: 16px;padding: 0px;margin: .5rem;">
		          <div class="col-12 col-sm-2 cat">
		            <div class="info-box-content text-light vertical-center" style="padding: 5px;">
		              <h5 class="info-box-number"><i class="fas fa-undo-alt"></i></h5>
		              <h5 class="info-box-text">Retur</h5>
		            </div>
		          </div>
		          <div class="col-12 col-sm-10 row margin-0">
		            <div class="col-12 col-sm-6 col-md-3">
		              <span @click="dashortcut('retok')" style="cursor: pointer;">
		                <div class="info-box mb-3 vertical-center box-border row">
		                  <div class="row" style="padding-left: 10px;">
		                    <i class="hxf hxf-returned" style="font-size: 24px;"></i><h5 class="info-text">Diserahkan</h5>
		                  </div>
		                  <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
		                    <div class="info-box-content text-dark" style="padding: 0px;" v-bind:class="{ 'skeleton-item': loading }">
		                      <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.returned }}</h5>
		                    </div>
		                  </div>
		                </div>
		              </span>
		              <!-- /.info-box -->
		            </div>
		            <div class="col-12 col-sm-6 col-md-3">
		              <span @click="dashortcut('retacc')" style="cursor: pointer;">
		                <div class="info-box mb-3 vertical-center box-border row">
		                  <div class="row" style="padding-left: 10px;">
		                    <i class="hxf hxf-returned" style="font-size: 24px;"></i><h5 class="info-text">Diterima</h5>
		                  </div>
		                  <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
		                    <div class="info-box-content text-dark" style="padding: 0px;" v-bind:class="{ 'skeleton-item': loading }">
		                      <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.retReceived }}</h5>
		                    </div>
		                  </div>
		                </div>
		              </span>
		              <!-- /.info-box -->
		            </div>
		          </div>
		        </div>		        
		        <!-- /.col -->
			</div>
			<div id="_sectionNotMove">
          		<div class="row mt-3">
					<!--TIDAK BERGERAK-->
					<div class="info-box row" style="width: 100%;border-radius: 16px;padding: 0px;margin: .5rem;">
					<div class="col-12 col-sm-2 cat">
						<div class="info-box-content text-light vertical-center" style="padding: 5px;">
						<h5 class="info-box-number"><i class="far fa-stop-circle"></i></h5>
						<h5 class="info-box-text">Tidak Bergerak</h5>
						</div>
					</div>
					<div class="col-12 col-sm-10 row margin-0">
						<div class="col-12 col-sm-6 col-md-3">
						<span @click="dashortcut('nmove5')" style="cursor: pointer;">
							<div class="info-box mb-3 vertical-center box-border row">
							<div class="row" style="padding-left: 10px;">
								<i class="hxf hxf-nochange" style="font-size: 24px;"></i><h5 class="info-text">>5 Hari</h5>
							</div>
							<div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
								<div class="info-box-content text-dark" style="padding: 0px;" v-bind:class="{ 'skeleton-item': loading2 }">
								<h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ notMove.notMove5 }}</h5>
								</div>
							</div>
							</div>
						</span>
						<!-- /.info-box -->
						</div>
						<div class="col-12 col-sm-6 col-md-3">
						<span @click="dashortcut('nmove10')" style="cursor: pointer;">
							<div class="info-box mb-3 vertical-center box-border row">
							<div class="row" style="padding-left: 10px;">
								<i class="hxf hxf-nochange" style="font-size: 24px;"></i><h5 class="info-text">>10 Hari</h5>
							</div>
							<div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
								<div class="info-box-content text-dark" style="padding: 0px;" v-bind:class="{ 'skeleton-item': loading2 }">
								<h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ notMove.notMove10 }}</h5>
								</div>
							</div>
							</div>
						</span>
						<!-- /.info-box -->
						</div>
						
						<div class="col-12 col-sm-6 col-md-3">
						<span @click="dashortcut('nmove14')" style="cursor: pointer;">
							<div class="info-box mb-3 vertical-center box-border row">
							<div class="row" style="padding-left: 10px;">
								<i class="hxf hxf-nochange text-primary"></i><h5 class="info-text">>14 hari</h5>
							</div>
							<div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
								<div class="info-box-content text-dark" style="padding: 0px;" v-bind:class="{ 'skeleton-item': loading2 }">
									<h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{notMove.notMove14}}</h5>
								</div>
							</div>
							</div>
						</span>
						<!-- /.info-box -->
						</div>
						<div class="col-12 col-sm-6 col-md-3">
						<span @click="dashortcut('rpick')" style="cursor: pointer;">
							<div class="info-box mb-3 vertical-center box-border row" v-if="false">
							<div class="row" style="padding-left: 10px;">
								<i class="far fa-edit fa-2x text-primary"></i><h5 class="info-text">Claim Breach</h5>
							</div>
							<div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
								<div class="info-box-content text-dark" style="padding: 0px;" v-bind:class="{ 'skeleton-item': loading2 }">
								<h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">0</h5>
								</div>
							</div>
							</div>
						</span>
						<!-- /.info-box -->
						</div>
					</div>
					</div>
				  </div>
			</div>
			<div id="_sectionTicket">
          		<div class="row mt-3">
					<!--TICKET-->
					<div class="info-box row" style="width: 100%;border-radius: 16px;padding: 0px;margin: .5rem;">
						<div class="col-12 col-sm-2 cat">
							<div class="info-box-content text-light vertical-center" style="padding: 5px;">
							<h5 class="info-box-number"><i class="far fa-stop-circle"></i></h5>
							<h5 class="info-box-text">Ticket & Claim</h5>
							</div>
						</div>
						<div class="col-12 col-sm-10 row margin-0">
						<div class="col-12 col-sm-6 col-md-3">
						<span @click="dashortcut('open')" style="cursor: pointer;">
							<div class="info-box mb-3 vertical-center box-border row">
							<div class="row" style="padding-left: 10px;">
								<i class="fas fa-inbox text-primary" style="font-size: 24px;"></i><h5 class="info-text">Open</h5>
							</div>
							<div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
								<div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading3 }" style="padding: 0px;">
								<h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ allTicket.open }}</h5>
								</div>
							</div>
							</div>
						</span>
						<!-- /.info-box -->
						</div>
						<div class="col-12 col-sm-6 col-md-3">
						<span @click="dashortcut('closed')" style="cursor: pointer;">
							<div class="info-box mb-3 vertical-center box-border row">
							<div class="row" style="padding-left: 10px;">
								<i class="fas fa-check-double text-primary" style="font-size: 24px;"></i><h5 class="info-text">Closed</h5>
							</div>
							<div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
								<div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading3 }" style="padding: 0px;">
								<h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ allTicket.closed }}</h5>
								</div>
							</div>
							</div>
						</span>
						<!-- /.info-box -->
						</div>
						<div class="col-12 col-sm-6 col-md-3">
						<span @click="dashortcut('retclm')" style="cursor: pointer;">
							<div class="info-box mb-3 vertical-center box-border row">
							<div class="row" style="padding-left: 10px;">
								<i class="hxf hxf-rtk text-primary" style="font-size: 24px;"></i><h5 class="info-text">Claim</h5>
							</div>
							<div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
								<div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading3 }" style="padding: 0px;">
								<h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.retClaim }}</h5>
								</div>
							</div>
							</div>
						</span>
						<!-- /.info-box -->
						</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row mt-2" v-if="false">
				<div class="col-md-12">
					<div class="info-box mb-3">
						<div class="info-box-content">
							<div class="row">
								<div class="col-md-12">
									<div class="row">
										<div class="col-auto">
											<label class="control-label my-1 ml-0">
												<h5>Nilai Uang Yang Bisa Dikumpulkan</h5>
											</label>
										</div>
										<div class="col-auto">
											<div class="input-group mb-3">
												<input type="text" ref="daterange2" class="form-control"
													style="border-right: 0" />
												<div class="input-group-append">
													<div class="input-group-text" style="background-color: #fff">
														<span>
															<span class="fa fa-calendar"></span>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="p-1"></div>
								<div class="col-12">
									<div class="row">
										<div class="col-6">
											<h5 class="info-box-text">
												<strong>COD</strong>
											</h5>
											<div class="table-responsive">
												<table class="table table-bordered">
													<thead></thead>
													<tbody>
														<tr>
															<th style="width: 40%">AKTUAL</th>
															<td>Rp {{ formatMoney(dataFinance.aktual) }}</td>
														</tr>
														<tr>
															<th style="width: 40%">DANA DI KURIR</th>
															<td>Rp {{ formatMoney(dataFinance.dana_terkirim) }}</td>
														</tr>
														<tr>
															<th style="width: 40%">DANA BELUM TERBAYAR</th>
															<td>
																Rp
																{{ formatMoney(dataFinance.belum_dibayar) }}
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
										<div class="col-6">
											<h5 class="info-box-text">
												<strong>NON COD</strong>
											</h5>
											<div class="table-responsive">
												<table class="table table-bordered">
													<thead></thead>
													<tbody>
														<tr></tr>
														<tr>
															<th style="width: 40%">DANA SUDAH ADA DI PIHAK BOSCOD</th>
															<td>Rp {{ formatMoney(dataFinance.dana_boscod) }}</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row mt-2" v-if="false">
				<div class="col-md-12">
					<div class="info-box mb-3">
						<div class="info-box-content">
							<div class="row">
								<div class="col-md-12">
									<div class="row">
										<div class="col-auto">
											<label class="control-label my-1 ml-0">
												<h5>Cashback</h5>
											</label>
										</div>
										<div class="col-auto">
											<div class="input-group mb-3">
												<input type="text" ref="daterange3" class="form-control"
													style="border-right: 0" />
												<div class="input-group-append">
													<div class="input-group-text" style="background-color: #fff">
														<span>
															<span class="fa fa-calendar"></span>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="p-1"></div>
								<div class="col-12">
									<div class="row justify-content-center">
										<div class="col col-md-4" v-if="dataStatus.courierId == 3">
											<div class="info-box">
												<img height="55" v-bind:src="url + '/assets/img/jne.png'" />
												<div class="info-box-content">
													<h4 class="info-box-number my-2">Rp
														{{ formatMoney(dataCashback.jne) }}</h4>
												</div>
											</div>
										</div>
										<div class="col col-md-4" v-if="dataStatus.courierId == 1">
											<div class="info-box">
												<img height="55" v-bind:src="url + '/assets/img/idx.png'" />
												<div class="info-box-content">
													<h4 class="info-box-number my-2">Rp
														{{ formatMoney(dataCashback.idx) }}</h4>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="_sectionChart" v-if="false">
				<div class="row mt-3">
					<div class="col-12">
						<div class="info-box">
							<div class="overlay" v-if="loading4"><i class="fas fa-4x fa-sync-alt fa-spin"></i></div>
							<canvas ref="myChart2"></canvas>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import Daterangepicker from "daterangepicker";
import $ from "jquery";
import Chart from "chart.js";
import { authFetch, auth, formatCurrency } from "@/libs/hxcore";
import moment from "moment";
import vSelect from "vue-select";
import "daterangepicker/daterangepicker.css";
import "vue-select/dist/vue-select.css";
var myChart = null;
var myChart2 = null;
export default {
  name: "Home",
  data: function() {
    return {
      isKurir: false,
      dateStart: moment()
        .day(-31)
        .format("YYYY-MM-DD"),
      dateEnd: moment().add(2, 'd').format("YYYY-MM-DD"),
      dataStatus: {
		  ticket: {}
	  },
      dataFinance: {},
      dataCashback: {},
      url: window.$apiUrl,
      allTicket: {},
	  notMove: {},
      provinces: [],
      cities: [],
      province: '0',
      city: "",
      dataCourierPie: {
        type: "pie",
        data: [],
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top"
            },
            title: {
              display: true,
              text: "Data Courier"
            }
          }
        }
      },
      dataCourierBar: {
        type: "bar",
        data: [],
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top"
            },
            title: {
              display: true,
              text: "Data"
            }
          }
        }
      },
      dt: {
        start: "",
        end: ""
      },
      infoBar: [],
      loading: false,
      loading2: false,
      loading3: false,
      loading4: false,
    };
  },
  components: {
    vSelect
  },
  created: function() {
    var info = auth.user();
    if (info) {
      this.username = info.display_name;
      this.isKurir = info.role_id == 9;
	}
	this.province = sessionStorage.origP;
	// this.loadAllStatus();
	this.loadProvinces();
	if(sessionStorage.origP)
		this.loadCities();
	this.city =	sessionStorage.origC;
  },
  computed: {
    isReady: function() {
      return !this.loading;
    }
  },
  methods: {
	dashortcut(dest) {
		let paramsData = '{"' + dest + '": 1';

		if (dest !== 'pbreach' && 
			dest !== 'open' && 
			dest !== 'closed' && 
			dest !== 'retclm') {
			paramsData += ', "dt1": "' + this.dateStart + '", "dt2": "' + this.dateEnd + '"';
		}

		sessionStorage.filterData = paramsData + '}';
		const routeData = this.$router.resolve({name: 'Riwayat Order'});
		window.open(routeData.href, '_blank');
	},
	selectOrigin() {
		this.city = '';
		sessionStorage.origC = '';
		if(!this.province)this.province='';
		sessionStorage.origP = this.province;
		this.onReload();
		this.loadedMove = false;
		this.loadedTicket = false;
		this.loadNotMove();
		this.loadAllStatus();
		this.loadedChart = false;
		this.loadCities = false;
	},
	selectCity() {
		if(!this.city)this.city='';

		sessionStorage.origC = this.city;
		this.onReload();
		this.loadedMove = false;
		this.loadedTicket = false;
		this.loadNotMove();
		this.loadAllStatus();
		this.loadedChart = false;
	},
	loadProvinces: function () {
		authFetch("/dashboard/kurir/provinces").then(res => {
			res.json().then(json => {
				if (json.success) {
					this.provinces = json.data;
				}
			});
		});
	},
	loadCities: function () {
		if(!this.province)return;
		authFetch("/dashboard/kurir/cities?id="+this.province).then(res => {
			res.json().then(json => {
				if (json.success) {
					this.cities = json.data;
				}
			});
		});
	},
    loadAllStatus() {
		if (this.loadedTicket == true) {
            return;
        } else {
			this.loadedTicket = true;
			this.loading3 = true
			authFetch("/ticket/history/all_tickets")
				.then(res => {
				if (res.status === 201) {
				} else if (res.status === 400) {
				}
				return res.json();
				})
				.then(js => {
				this.loading3 = false
				this.allTicket = js.data;
				$('.loading-overlay').removeClass('show');
				});
		}
    },
    loadFinance() {
      var params = { dt1: this.dateStart, dt2: this.dateEnd };
      this.loading = true;
      var search = new URLSearchParams(params);
      authFetch(`/dashboard/kurir/financial?${search}`).then(res => {
        res.json().then(json => {
          this.loading = false;
          if (json.success) {
            this.dataFinance = json.data;
          }
        });
      });
    },
    formatMoney(val) {
      return formatCurrency(val);
    },
    onReload() {
      var params = { dt1: this.dateStart, dt2: this.dateEnd, prid: this.province?this.province:'', ctid: this.city?this.city:'' };
      this.loading = true;
      var search = new URLSearchParams(params);
      authFetch(`/dashboard/kurir?${search}`).then(res => {
        res.json().then(json => {
          this.loading = false;
          if (json.success) {
            this.dataStatus = json.status;
          }
        });
        //loading(false);
      });
    },
	loadNotMove: function () {
        if (this.loadedMove == true) {
            return;
        } else {
            this.loadedMove = true;
            var params = { dt1: this.dateStart, dt2: this.dateEnd, prid: this.province?this.province:'', ctid: this.city?this.city:'' };
            this.loading2 = true;
            var search = new URLSearchParams(params);
            authFetch(`/dashboard/kurir/not_move?${search}`)
                .then((res) => {
                res.json().then((json) => {
                this.loading2 = false;
                if (json.success) {
                    this.notMove = json.status;
                }
                });
        });
        }
    },
    loadStatusChart: function () {
      if (this.loadedChart == true) {
            return;
        } else {
            this.loadedChart = true;
            var params = { dt1: this.dateStart, dt2: this.dateEnd, prid: this.province?this.province:'', ctid: this.city?this.city:'' };
            this.loading4 = true;
            var search = new URLSearchParams(params);
            authFetch(`/dashboard/kurir/statistic_chart?${search}`).then((res) => {
                res.json().then((json) => {
                this.loading4 = false;
                if (json.success) {
                    this.dataCourierBar.data = json.status.barChart;
                    myChart2.update();
                }
                });
            });
        }
    },
    loadCashback() {
      var params = { dt1: this.dateStart, dt2: this.dateEnd };
      this.loading = true;
      var search = new URLSearchParams(params);
      authFetch(`/dashboard/cashback?${search}`).then(res => {
        res.json().then(json => {
          this.loading = false;
          if (json.success) {
            this.dataCashback = json.data;
          }
        });
      });
    }
  },
  mounted() {
    let self = this;

    //daterange all
    new Daterangepicker(
      this.$refs.daterange,
      {
        startDate: moment().day(-31),
        endDate: moment()
      },
      function(d1, d2) {
        self.dateStart = d1.format("YYYY-MM-DD");
        self.dateEnd = d2.format("YYYY-MM-DD");
        self.onReload();
		self.loadedMove = false;
		self.loadedTicket = false;
		self.loadNotMove();
		self.loadAllStatus();
		self.loadedChart = false;
      }
    );

    //daterange finance
    new Daterangepicker(
      this.$refs.daterange2,
      {
        startDate: moment().day(-31),
        endDate: moment()
      },
      function(d1, d2) {
        self.dateStart = d1.format("YYYY-MM-DD");
        self.dateEnd = d2.format("YYYY-MM-DD");
        //self.loadFinance();
      }
    );

    //daterange cashback
    new Daterangepicker(
      this.$refs.daterange3,
      {
        startDate: moment().day(-31),
        endDate: moment().add(2, 'd')
      },
      function(d1, d2) {
        self.dateStart = d1.format("YYYY-MM-DD");
        self.dateEnd = d2.format("YYYY-MM-DD");
        self.loadCashback();
      }
    );

    this.onReload();
	this.loadedMove = false;
	this.loadedTicket = false;
	this.loadNotMove();
	this.loadAllStatus();
    // this.loadCashback();
    //this.loadFinance();
    // var ctx = this.$refs.myChart.getContext("2d");
    // var ctx2 = this.$refs.myChart2.getContext("2d");

    // // myChart = new Chart(ctx, this.dataCourierPie);
    // myChart2 = new Chart(ctx2, this.dataCourierBar);
	// window.addEventListener('scroll', function (event) {
    //     // const self = this;
    //     if ($('#_sectionTicket').length == 0) {
    //       console.log('tes');
    //         return;
    //     }
    //     var hT2 = $('#_sectionNotMove').offset().top,
    //         hH2 = $('#_sectionNotMove').outerHeight(),
    //         hT3 = $('#_sectionTicket').offset().top,
    //         hH3 = $('#_sectionTicket').outerHeight(),
    //         hT4 = $('#_sectionChart').offset().top,
    //         hH4 = $('#_sectionChart').outerHeight(),
    //         wH = $(window).height(),
    //         wS = $(this).scrollTop();
    //     console.log(hT2);
    //     // console.log(hT2, hH2, wH, wS);
    //     if (wS > (hT2+hH2-wH)){
    //       console.log('tes masuk');
    //         //load ticket
    //         self.loadNotMove();
    //     } 
    //     if (wS > (hT3+hH3-wH)){
    //         //load chart
    //         self.loadAllStatus();
    //     } 
    //     if (wS > (hT4+hH4-wH)){
    //         //load finance report
    //         self.loadStatusChart();
    //     }
    // });
  }
};
</script>
<style>
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  /*text-align: center;*/
}

.text-center-v {
  align-self: center;
}

.text-padding-12 {
  padding-top: 14px;
  overflow-wrap: anywhere;
}

@media (min-width: 300px) and (max-width: 1023px) {
  .text-right {
    text-align: left !important;
  }

  .text-padding-12 {
    padding-top: 0px;
    overflow-wrap: anywhere;
  }
}
.text-light {
  color: white;
}
.vertical-center {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.box-border {
  border: 1.5px #C4C4C4 solid;
}
.info-box .info-box-text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
}
.text-higher {
    font-size: large;
}
.info-text{
    color: initial;
    padding: 3px;
}
.cat {
  text-align: center;
  vertical-align: middle;
  background-color: rgb(41, 47, 65);
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
}
@media (min-width: 481px) and (max-width: 767px) {
  .cat {
    border-radius: 0px;
  }
  .margin-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .rasio {
    margin-bottom: 12px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .info-box .cat {
    border-radius: 0px;
  } 
  .margin-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .rasio {
    margin-bottom: 12px;
  }
}
@media (max-width: 320px) {
  .info-box .cat {
    border-radius: 0px;
  } 
  .margin-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .rasio {
    margin-bottom: 12px;
  }
}
</style>